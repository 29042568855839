.list {
  width: 232px;
  
  .header-image {
    width: 100%;
    display: flex;
    /* justify-content: center; */
    padding: 16px 0 0 32px;
  }
  .copy-right {
    padding-left: 16px;
  }
}

.toolbar {
    height: 156px;
    width:100%;
    position:fixed;
    top:0;
    left:0;
    background-color:white;
    display: flex;
    justify-content: left;
    padding: 0;
    box-sizing:border-box;
    z-index:90;
    border-bottom: thin solid lightgrey;
    text-align: left;
}

.logo-col {
    display: inline-block;
    padding: 32px 32px;
}

.header-col {
    display: inline-block;
    height: 156px;
}

.flex-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.logo {
    width: 96px;
    height: 96px;
    text-align: right;
    /* padding: 24px; */
} 

.page-title {
    display: block;
    padding-top: 34px;
    flex-grow: 1;
    margin:0px;
    color:#006caa;
    /* padding: "8px 16px 16px 16px"; */
}

.header-button {
    display: inline-block;
    margin: 0 0 24px -10px;
}

@media (max-width: 1200px) {
  .page-title {
    padding-top: 48px;
  }
}

@media (max-width: 768px) {
    .toolbar {
        height: 64px;
        background-color:#006CAA;
        justify-content: center;
        padding: 0;
        box-sizing:border-box;
        z-index:90;
        border-bottom: thin solid lightgrey;
        text-align: left;
    }
    .page-title {
        top: 0px;
        padding-top: 0;
        color: white;
        width: 100%;
        display: inline-block;
    }
    .logo-col {
        display: inline-block;
        padding: 0 24px 0 0;
    }
    .logo {
        width: 40px;
        height: 40px;
        border-radius: 8px;
    }
    .menu-button {
        margin: '0px';
        height: '40px';
        width: '40px';
        border-radius: '8px';
        padding: '0px';
        float: right;
    }
}