.app-layout {
  height: 100vh;
  width: 100%;
  overflow: hidden;
}

.page-content {
  background-color: white;
  padding-top: 156px;
  overflow-y: scroll;                                                                                                                        
  height: calc(100vh - 156px - 32px);
}

@media (max-width: 768px) {
  .page-content {
    padding-top: 64px;
    height: calc(100vh - 64px - 32px);
  }
}

.footer {
  position: fixed !important;
  bottom: 0;
  height: 32px;
}

.footer > .footer-text {
  padding: 8px 24px;
}

