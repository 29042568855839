.form-item{
  margin:4px 0px;
}

.form-label-large{
font-size:20px;
font-weight:700;
text-align: left;
}

.form-label{
font-size:14px;
font-weight:700;
}

.button-right{
float:right;
z-index:99;
}

.heading {

  .label {
    background-color: #B2D7ED;
    padding: 8px 16px;
    width: calc(100% - 32px);
  }
}
