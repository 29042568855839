.image-col {
    max-width: 300px;
}

.program-image {
    max-width: 332px;
    width: calc(100% - 0px);
    padding: 0;
    display: block;
}

.mobile-program-title {
    /* text-align: center; */
    width: 100%;
}

.column {
  padding: 0 16px;
}
    
@media (max-width: 768px) {
    .program-image {
        margin-left: 0;
        margin-right: 0;
        width: 80%;
        min-width: 280px;
    }
}
